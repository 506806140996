<script setup lang="tsx">
  import { PencilIcon } from '@heroicons/vue/solid';
  import { get } from '~/services/donationScreen';
  import type { ResponseStatus } from '~/types';
  import InfoRow from '~/components/UI/Drawers/InfoRow.vue';
  import InfoSection from '~/components/UI/Drawers/InfoSection.vue';
  import DrawerLayout from '~/components/UI/Drawers/DrawerLayout.vue';
  import { checkValueAndLength } from '~/functions/stringUtilities';
  import RoleBasedLinkButton from '~/components/UI/Others/RoleBasedLinkButton.vue';
  import ComponentSectionRow from '~/components/UI/Drawers/ComponentSectionRow.vue';
  import Attachments from '../../Others/Attachments.vue';
  import AuditLogs from '../RoutedDrawerComponents/AuditLogs.vue';
  import AttachmentUpdates from '../RoutedDrawerComponents/AttachmentUpdates.vue';
  import { formatToIndianCurrency } from '~/functions/currencyUtilities';
  import RoleBasedButton from '~/components/UI/Others/RoleBasedButton.vue';
  import type { BudgetsDrawerType } from '~/types/budgets/budgetsDrawer';
  import BudgetLineItem from '../RoutedDrawerComponents/BudgetLineItem.vue';
  import DenyBudgetModal from '../../Modals/DenyBudgetModal.vue';
  import ApproveBudgetModal from '../../Modals/ApproveBudgetModal.vue';

  const { onClose, identifier } = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const denyBudgetModal = ref<boolean>(false);
  const approveBudgetModal = ref<boolean>(false);

  const data = ref<BudgetsDrawerType>({} as BudgetsDrawerType);

  const responseState = ref<ResponseStatus>('idle');

  const extractedNames = computed(() => {
    if (!data.value?.selected_budget?.budget_members) return [];

    return data.value?.selected_budget?.budget_members
      ?.split(',')
      .filter((entry: string) => entry.trim()) // Remove empty entries
      .map((entry: string) => {
        const nameParts = entry.trim().split('_');
        nameParts.pop();
        return nameParts.join(' ');
      });
  });

  const showDenyBudgetModal = () => {
    denyBudgetModal.value = true;
  };

  const hideDenyBudgetModal = () => {
    denyBudgetModal.value = false;
    fetchData(); // Refresh data after closing modal
  };

  const showApproveBudgetModal = () => {
    approveBudgetModal.value = true;
  };

  const hideApproveBudgetModal = () => {
    approveBudgetModal.value = false;
    fetchData(); // Refresh data after closing modal
  };

  const fetchData = async () => {
    responseState.value = 'pending';
    try {
      const response = await get<BudgetsDrawerType>(`v1/get_budget/${identifier}`);
      if (response.status === 200) {
        data.value = response.data;
        responseState.value = 'success';
      } else {
        responseState.value = 'error';
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  onMounted(fetchData);
</script>

<template>
  <a-drawer
    v-if="identifier"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    title="Budget Information"
    :open="identifier !== null"
    width="1200"
    @close="onClose"
  >
    <template #extra>
      <div v-if="!data?.selected_budget?.is_closed" class="flex gap-2">
        <RoleBasedLinkButton
          :to="{ path: '/budgets/edit-budget', query: { identifier } }"
          component-name="editActionButton"
          :disabled="!data?.selected_budget?.is_current_approver"
          active-class="text-blue-700 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center text-gray-500 border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          disabled-class="flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
        >
          <PencilIcon class="h-4 w-4" />
          Edit Budget
        </RoleBasedLinkButton>
        <RoleBasedButton
          component-name="closeActionButton"
          :disabled="!data?.selected_budget?.is_current_approver"
          active-class="!text-white hover:!text-white bg-red-700 hover:bg-red-600 disabled:!text-red-300 disabled:border-red-300 disabled:bg-red-50 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-red-700 hover:!border-red-600 shadow-[0_1px_3px_0px_#0000001A,0_1px_2px_0_#0000000F]"
          disabled-class="flex rounded items-center !text-red-300 gap-1 text-sm font-medium py-1.5 px-2 justify-center border !border-red-300 shadow-[0_1px_2px_0px_#0000000D]"
          @click="showDenyBudgetModal"
        >
          Deny Budget
        </RoleBasedButton>
        <RoleBasedButton
          component-name="approveActionButton"
          :disabled="!data?.selected_budget?.is_current_approver"
          active-class="!text-white hover:!text-white bg-green-700 hover:bg-green-600 disabled:!text-green-300 disabled:border-green-300 disabled:bg-green-50 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-green-700 hover:!border-green-600 shadow-[0_1px_3px_0px_#0000001A,0_1px_2px_0_#0000000F]"
          disabled-class="flex rounded items-center !text-green-500 gap-1 text-sm font-medium py-1.5 px-2 justify-center !bg-green-100 border !border-green-500 shadow-[0_1px_2px_0px_#0000000D]"
          @click="showApproveBudgetModal"
        >
          Approve Budget
        </RoleBasedButton>
      </div>
      <p v-else class="text-base font-bold text-red-600">Grant is Closed</p>
    </template>
    <DrawerLayout
      :response-state="responseState"
      :main-display="checkValueAndLength(data?.selected_budget?.description) || '-'"
      :sub-display="checkValueAndLength(data?.selected_budget?.budget_id) || '-'"
      :side-display="formatToIndianCurrency(data?.selected_budget?.amount)"
      @close="onClose"
    >
      <template #column1>
        <InfoSection>
          <InfoRow label="Budget Financial Year" :value="checkValueAndLength(data?.selected_budget?.financial_year)" />
          <InfoRow label="Description" :value="checkValueAndLength(data?.selected_budget?.description)" />
          <ComponentSectionRow label="Budget Members" class="px-3 py-2">
            <div class="flex gap-3 flex-wrap">
              <p v-for="name in extractedNames" :key="name" class="bg-zinc-100 px-1 py-0.5 rounded-md">
                {{ name }}
              </p>
            </div>
          </ComponentSectionRow>
        </InfoSection>
        <BudgetLineItem :items="data?.selected_budget?.line_items" />
      </template>
      <template #column2>
        <Attachments :items="data?.selected_budget?.attachments_data" :table="data?.selected_budget?.table" />
        <AuditLogs :items="data?.audit_log_data" />
        <AttachmentUpdates :items="data?.attachment_log_data" />
      </template>
    </DrawerLayout>
  </a-drawer>
  <DenyBudgetModal :visible="denyBudgetModal" :close="hideDenyBudgetModal" :budget-id="identifier" />
  <ApproveBudgetModal :visible="approveBudgetModal" :close="hideApproveBudgetModal" :budget-id="identifier" />
</template>

<style>
  @import '~/assets/css/donationDrawer.css';
</style>
