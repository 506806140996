<!-- This component is used to create a dropdown menu.  For this component `stateKey` should be an object with key `title` that will show up in th dropdown and value that will the value for of the dropdown.If `formItemClass` is not working try add `!` to make it important will giving props. To use this component you also need to define a function which will update the state in the parent component something like this:
```
const updateDropdown = (value: string, stateKey: keyof typeof formState) => {
    (formState as any)[stateKey] = value;
  };
```
 -->
<script lang="ts" setup>
  const props = defineProps<{
    isCompulsory?: boolean;
    labelText: string;
    name: string;
    value: string | number | null;
    disabled?: boolean;
    disabledText?: string; // text to display when disabled
    options: { title: string; value: string | number }[];
    stateKey: string; // key to store the value in the form state in the parent component
    rule?: any; // validation rule for the dropdown
    formItemClass?: string;
  }>();

  const localValue = ref<string | number | null>(props?.value);

  const emit = defineEmits(['update:value']);

  watch(
    () => props.value,
    newValue => {
      localValue.value = newValue;
    }
  );

  watch(
    () => localValue.value,
    newValue => {
      emit('update:value', newValue, props.stateKey);
    }
  );
</script>
<template>
  <a-form-item :class="`!font-medium mb-3 dropdown-component ${formItemClass}`" :rules="rule" :name="props.name">
    <template #label>
      <p class="text-zinc-600">
        {{ props.labelText }}<span v-if="props.isCompulsory" class="text-red-500 ml-1">*</span>
      </p>
    </template>
    <a-select v-model:value="localValue" style="width: 248px" :disabled="disabled">
      <a-select-option v-if="props.disabledText" value="" disabled>{{ props.disabledText }}</a-select-option>
      <a-select-option v-for="item in props.options" :key="item.value" :value="item.value" :title="item.title">
        {{ item.title }}
      </a-select-option>
    </a-select>
  </a-form-item>
</template>

<style>
  .dropdown-component .ant-form-item-label > label {
    color: #52525b !important;
    line-height: 21px;
  }

  .dropdown-component .ant-select-selector {
    padding: 8px !important;
    height: 39px !important;
    width: 248px !important;
  }

  .dropdown-component .ant-select-selection-item {
    color: #27272a;
    line-height: 21px !important;
    font-weight: 500 !important;
  }

  .dropdown-component .ant-select-selection-placeholder {
    line-height: 21px !important;
    font-weight: 500 !important;
  }
</style>
