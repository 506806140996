<script setup lang="tsx">
  import CustomDropdown from '~/components/UI/Forms/CustomDropdown.vue';
  import CustomInput from '~/components/UI/Forms/CustomInput.vue';
  import { get, post } from '~/services/donationScreen';
  import type { CostCentreDrawer, GetResponse } from '~/types';

  const props = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const route = useRoute();

  const action = computed(() => {
    return route.query.action as string;
  });

  const formRef = ref();
  const formState = reactive({
    costCentreName: '',
    costCategoryName: 'Primary Cost Category',
    booksOfAccount: ''
  });

  const fetchData = async () => {
    try {
      const costCentreResponse = await get<GetResponse<CostCentreDrawer>>(`v1/get_cost_centre/${props.identifier}`);
      if (costCentreResponse.data) {
        Object.assign(formState, {
          costCentreName: costCentreResponse?.data?.data?.selected_cost_centre?.cost_centre_name || '',
          costCategoryName: costCentreResponse?.data?.data?.selected_cost_centre?.cost_category_name || '',
          booksOfAccount: costCentreResponse?.data?.data?.selected_cost_centre?.is_fcra || ''
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const rules = {
    costCentreName: [
      {
        required: true,
        message: 'Cost Centre Name is required',
        trigger: 'submit'
      }
    ],
    costCategoryName: [
      {
        required: true,
        message: 'Cost Category Name is required',
        trigger: 'submit'
      }
    ],
    booksOfAccount: [
      {
        required: true,
        message: 'Books of Account is required',
        trigger: 'submit'
      }
    ]
  };

  const updateStateField = (value: string, stateKey: keyof typeof formState) => {
    (formState as any)[stateKey] = value;
  };

  const handleCreateCostCentre = async () => {
    try {
      await formRef.value.validate();
      const endpoint = action.value === 'edit' ? `v1/cost_centre/${props.identifier}` : 'v1/add_new_cost_centre';
      await post(endpoint, {
        data: {
          cost_centre_name: formState.costCentreName,
          cost_category_name: formState.costCategoryName,
          is_fcra: formState.booksOfAccount
        }
      });
      openSuccessfulCustomNotification('Cost Centre Created Successfully');
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log(error);
      openFailedCustomNotification('Failed to Create Cost Centre');
    }
  };

  onMounted(() => {
    if (props.identifier) {
      fetchData();
    }
  });
</script>

<template>
  <a-drawer
    :open="route.query.drawerFrom === 'costCentres'"
    title="Add New Cost Centre"
    width="750"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    @close="props.onClose"
  >
    <template #extra>
      <a-button class="!px-3 !py-2 w-fit h-fit !rounded" type="primary" @click="handleCreateCostCentre"
        >Create Cost Centre</a-button
      >
    </template>
    <a-form
      ref="formRef"
      class="flex flex-col gap-4 p-3 setup-drawer-form"
      :model="formState"
      :rules="rules"
      :colon="false"
      :label-col="{ style: { width: '250px', textAlign: 'left' } }"
      hide-required-mark="true"
    >
      <CustomInput
        label-text="Cost Centre Name"
        name="costCentreName"
        :value="formState.costCentreName"
        state-key="costCentreName"
        :is-compulsory="true"
        @update:value="updateStateField"
      />
      <CustomInput
        label-text="Cost Category Name"
        name="costCategoryName"
        :value="formState.costCategoryName"
        state-key="costCategoryName"
        :rule="rules.costCategoryName"
        :is-compulsory="true"
        @update:value="updateStateField"
      />
      <CustomDropdown
        :options="[
          { title: 'FCRA Books', value: 'FCRA Books' },
          { title: 'Non-FCRA Books', value: 'Non-FCRA Books' }
        ]"
        label-text="Books of Account"
        name="booksOfAccount"
        :value="formState.booksOfAccount"
        state-key="booksOfAccount"
        :rule="rules.booksOfAccount"
        :is-compulsory="true"
        @update:value="updateStateField"
      />
    </a-form>
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';

  .setup-drawer-form .ant-form-item {
    margin-bottom: 0px;
  }
</style>
