<script setup lang="tsx">
  import CustomDropdown from '~/components/UI/Forms/CustomDropdown.vue';
  import CustomInput from '~/components/UI/Forms/CustomInput.vue';
  import { get, post } from '~/services/donationScreen';
  import type { GetResponse, LedgerDrawer } from '~/types';

  const props = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const route = useRoute();

  const action = computed(() => {
    return route.query.action as string;
  });

  const formRef = ref();
  const formState = reactive({
    ledgerName: '',
    ledgerType: '',
    booksOfAccount: ''
  });

  const fetchData = async () => {
    try {
      const ledgerResponse = await get<GetResponse<LedgerDrawer>>(`v1/get_ledger/${props.identifier}`);
      if (ledgerResponse.data) {
        Object.assign(formState, {
          ledgerName: ledgerResponse?.data?.data?.selected_ledger?.ledger_name || '',
          ledgerType: ledgerResponse?.data?.data?.selected_ledger?.ledger_type || '',
          booksOfAccount: ledgerResponse?.data?.data?.selected_ledger?.is_fcra || ''
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const rules = {
    ledgerName: [
      {
        required: true,
        message: 'Ledger Name is required',
        trigger: 'submit'
      }
    ],
    ledgerType: [
      {
        required: true,
        message: 'Ledger Type is required',
        trigger: 'submit'
      }
    ],
    booksOfAccount: [
      {
        required: true,
        message: 'Books of Account is required',
        trigger: 'submit'
      }
    ]
  };

  const updateStateField = (value: string, stateKey: keyof typeof formState) => {
    (formState as any)[stateKey] = value;
  };

  const handleCreateLedger = async () => {
    try {
      await formRef.value.validate();
      const endpoint = action.value === 'edit' ? `v1/ledger/${props.identifier}` : 'v1/add_new_ledger';
      await post(endpoint, {
        data: {
          ledger_name: formState.ledgerName,
          ledger_type: formState.ledgerType,
          is_fcra: formState.booksOfAccount
        }
      });
      openSuccessfulCustomNotification('Ledger Created Successfully');
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log(error);
      openFailedCustomNotification('Failed to Create Ledger');
    }
  };

  onMounted(() => {
    if (props.identifier) {
      fetchData();
    }
  });
</script>

<template>
  <a-drawer
    :open="route.query.drawerFrom === 'ledgers'"
    title="Add New Ledger"
    width="750"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    @close="props.onClose"
  >
    <template #extra>
      <a-button class="!px-3 !py-2 w-fit h-fit !rounded" type="primary" @click="handleCreateLedger"
        >Create Ledger</a-button
      >
    </template>
    <a-form
      ref="formRef"
      class="flex flex-col gap-4 p-3 setup-drawer-form"
      :model="formState"
      :rules="rules"
      :colon="false"
      :label-col="{ style: { width: '250px', textAlign: 'left' } }"
      hide-required-mark="true"
    >
      <CustomInput
        label-text="Ledger Name"
        name="ledgerName"
        :value="formState.ledgerName"
        state-key="ledgerName"
        :is-compulsory="true"
        @update:value="updateStateField"
      />
      <CustomDropdown
        :options="[
          { title: 'Bank / Cash / Receipt Ledger', value: 'cash-bank' },
          { title: 'Donation Ledger', value: 'income' },
          { title: 'Supplier / Vendor Ledger', value: 'creditor' },
          { title: 'Expense Ledger', value: 'expense' },
          { title: 'Fixed Asset Ledger', value: 'fixed_asset' },
          { title: 'TDS Ledger', value: 'tds' }
        ]"
        label-text="Ledger Type"
        name="ledgerType"
        :value="formState.ledgerType"
        state-key="ledgerType"
        :rule="rules.ledgerType"
        :is-compulsory="true"
        @update:value="updateStateField"
      />
      <CustomDropdown
        :options="[
          { title: 'FCRA Books', value: 'FCRA Books' },
          { title: 'Non-FCRA Books', value: 'Non-FCRA Books' }
        ]"
        label-text="Books of Account"
        name="booksOfAccount"
        :value="formState.booksOfAccount"
        state-key="booksOfAccount"
        :rule="rules.booksOfAccount"
        :is-compulsory="true"
        @update:value="updateStateField"
      />
    </a-form>
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';

  .setup-drawer-form .ant-form-item {
    margin-bottom: 0px;
  }
</style>
