<script setup lang="tsx">
  import CustomDropdown from '~/components/UI/Forms/CustomDropdown.vue';
  import CustomInput from '~/components/UI/Forms/CustomInput.vue';
  import CustomTextArea from '~/components/UI/Forms/CustomTextArea.vue';
  import { get, post } from '~/services/donationScreen';
  import type { GetResponse, ContactTypeDrawer, ResponseStatus } from '~/types';
  import AuditLogs from '../RoutedDrawerComponents/AuditLogs.vue';

  const props = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const route = useRoute();

  const action = computed(() => {
    return route.query.action as string;
  });

  const formRef = ref();
  const formState = reactive({
    contactTypeName: '',
    contactTypeCategory: '',
    description: ''
  });

  const responseState = ref<ResponseStatus>('idle');
  const data = ref<ContactTypeDrawer>({} as ContactTypeDrawer);

  const fetchData = async () => {
    responseState.value = 'pending';
    try {
      const response = await get<GetResponse<ContactTypeDrawer>>(`v1/get_donor_type/${props.identifier}`);
      if (response.status === 200) {
        data.value = response.data.data;
        Object.assign(formState, {
          contactTypeName: response?.data?.data?.selected_donor_type?.donor_type_name || '',
          contactTypeCategory: response?.data?.data?.selected_donor_type?.donor_type_category || '',
          description: response?.data?.data?.selected_donor_type?.donor_type_description || ''
        });
        responseState.value = 'success';
      } else {
        responseState.value = 'error';
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      responseState.value = 'error';
    }
  };

  const rules = {
    contactTypeName: [
      {
        required: true,
        message: 'Contact Type Name is required',
        trigger: 'submit'
      }
    ],
    contactTypeCategory: [
      {
        required: true,
        message: 'Contact Type Category is required',
        trigger: 'submit'
      }
    ],
    description: []
  };

  const updateStateField = (value: string, stateKey: keyof typeof formState) => {
    (formState as any)[stateKey] = value;
  };

  const handleCreateContactType = async () => {
    try {
      await formRef.value.validate();
      const endpoint = route.query.action === 'edit' ? `v1/donor_type/${props.identifier}` : 'v1/add_donor_type';

      await post(endpoint, {
        data: {
          donor_type_name: formState.contactTypeName,
          donor_type_category: formState.contactTypeCategory,
          donor_type_description: formState.description
        }
      });

      openSuccessfulCustomNotification('Contact Type Created Successfully');
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log(error);
      openFailedCustomNotification('Failed to Create Contact Type');
    }
  };

  onMounted(() => {
    if (props.identifier) {
      fetchData();
    }
  });
</script>

<template>
  <a-drawer
    :open="route.query.drawerFrom === 'contactTypes'"
    title="Add New Contact Type"
    width="750"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    @close="props.onClose"
  >
    <template #extra>
      <a-button
        v-if="!props.identifier || action"
        class="!px-3 !py-2 w-fit h-fit !rounded"
        type="primary"
        @click="handleCreateContactType"
        >Create Contact Type</a-button
      >
    </template>
    <a-form
      ref="formRef"
      class="flex flex-col gap-4 p-3 setup-drawer-form"
      :model="formState"
      :rules="rules"
      :colon="false"
      :label-col="{ style: { width: '183px', textAlign: 'left' } }"
      hide-required-mark="true"
    >
      <CustomInput
        label-text="Contact Type Name"
        name="contactTypeName"
        :value="formState.contactTypeName"
        state-key="contactTypeName"
        :is-compulsory="true"
        @update:value="updateStateField"
      />
      <CustomDropdown
        :options="[
          { title: 'Individuals', value: 'Individuals' },
          { title: 'Corporate', value: 'Corporate' },
          { title: 'Foundation', value: 'Foundation' },
          { title: 'Government', value: 'Government' },
          { title: 'Anonymous', value: 'Anonymous' },
          { title: 'NGO', value: 'NGO' }
        ]"
        label-text="Contact Type Category"
        name="contactTypeCategory"
        :value="formState.contactTypeCategory"
        state-key="contactTypeCategory"
        :rule="rules.contactTypeCategory"
        :is-compulsory="true"
        @update:value="updateStateField"
      />
      <CustomTextArea
        label="Description"
        name="description"
        :value="formState.description"
        state-key="description"
        @update:value="updateStateField"
      />
      <AuditLogs v-if="responseState === 'success'" :items="data?.audit_log_data" />
    </a-form>
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';

  .setup-drawer-form .ant-form-item {
    margin-bottom: 0px;
  }
</style>
