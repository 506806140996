<script setup lang="tsx">
  import { PencilIcon, PlusIcon } from '@heroicons/vue/solid';
  import { get } from '~/services/donationScreen';
  import type { ResponseStatus } from '~/types';
  import InfoRow from '~/components/UI/Drawers/InfoRow.vue';
  import InfoSection from '~/components/UI/Drawers/InfoSection.vue';
  import DrawerLayout from '~/components/UI/Drawers/DrawerLayout.vue';
  import { checkValueAndLength } from '~/functions/stringUtilities';
  import RoleBasedLinkButton from '~/components/UI/Others/RoleBasedLinkButton.vue';
  import Attachments from '../../Others/Attachments.vue';
  import AuditLogs from '../RoutedDrawerComponents/AuditLogs.vue';
  import AttachmentUpdates from '../RoutedDrawerComponents/AttachmentUpdates.vue';
  import { formatDate } from '~/functions/dateTimeUtilities';
  import { formatToIndianCurrency } from '~/functions/currencyUtilities';
  import DisbursementDates from '../RoutedDrawerComponents/DisbursementDates.vue';
  import DonationsRecieved from '../RoutedDrawerComponents/DonationsRecieved.vue';
  import OverviewCard from '~/components/UI/Others/OverviewCard.vue';
  import type { PledgesDrawerType } from '~/types/pledges/pledgesDrawer';
  import ComponentSection from '~/components/UI/Drawers/ComponentSection.vue';
  import { pledgeTrenchFrequencyOptions } from '~/components/Pledges/Forms/pledgeFormUtils';
  import RoleBasedButton from '~/components/UI/Others/RoleBasedButton.vue';
  import ClosePledgeModal from '../../Modals/ClosePledgeModal.vue';

  const { onClose, identifier } = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const data = ref<PledgesDrawerType>({} as PledgesDrawerType);

  const closePledgeModal = ref<boolean>(false);

  const responseState = ref<ResponseStatus>('idle');

  const pendingDonationAmount = computed(() => {
    if (!data.value?.selected_pledge?.amount || !data.value?.donations_received) return 0;

    const totalReceived = data.value?.donations_received.reduce((acc, curr) => acc + +curr.amount, 0);
    return +data.value?.selected_pledge?.amount - totalReceived;
  });

  const extractedNames = computed(() => {
    if (!data.value?.reminder_users_list) return '';

    return data.value?.reminder_users_list.map(entry => `${entry.first_name} ${entry.last_name}`).join(', ');
  });

  const showClosePledgeModal = () => {
    closePledgeModal.value = true;
  };

  const hideClosePledgeModal = () => {
    closePledgeModal.value = false;
    fetchData(); // Refresh data after closing modal
  };

  const fetchData = async () => {
    try {
      const response = await get<PledgesDrawerType>(`v1/get_pledge/${identifier}`);
      if (response.status === 200) {
        data.value = response.data;
        responseState.value = 'success';
      } else {
        responseState.value = 'error';
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  onMounted(fetchData);
</script>

<template>
  <a-drawer
    v-if="identifier"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    title="Pledge Information"
    :open="identifier !== null"
    width="1200"
    @close="onClose"
  >
    <template #extra>
      <div v-if="!data?.selected_pledge?.is_closed" class="flex gap-2">
        <RoleBasedLinkButton
          :to="{ path: '/pledges/edit-pledge', query: { identifier } }"
          component-name="editActionButton"
          active-class="text-blue-700 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center text-gray-500 border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          disabled-class="flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
        >
          <PencilIcon class="h-4 w-4" />
          Edit Pledge
        </RoleBasedLinkButton>
        <RoleBasedButton
          component-name="closeActionButton"
          active-class="text-blue-700 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center text-gray-500 border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          disabled-class="flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          @click="showClosePledgeModal"
        >
          Close Pledge
        </RoleBasedButton>
      </div>
      <p v-else class="text-base font-bold text-red-600">Pledge is Closed</p>
    </template>
    <DrawerLayout
      :response-state="responseState"
      :main-display="checkValueAndLength(data?.selected_donor?.name) || '-'"
      :sub-display="checkValueAndLength(data?.selected_pledge?.pledge_id) || '-'"
      :side-display="formatToIndianCurrency(+data?.selected_pledge?.amount)"
      @close="onClose"
    >
      <template #column1>
        <InfoSection>
          <InfoRow label="Donor Name" :value="checkValueAndLength(data?.selected_donor?.name)" />
        </InfoSection>
        <InfoSection>
          <InfoRow label="Pledge ID" :value="checkValueAndLength(data?.selected_pledge?.pledge_id)" />
          <InfoRow label="Purpose" :value="checkValueAndLength(data?.selected_pledge?.purpose)" />
          <InfoRow label="Pledge Start Date" :value="formatDate(data?.selected_pledge?.start_date)" />
        </InfoSection>
        <InfoSection>
          <InfoRow label="Pledge Description" :value="checkValueAndLength(data?.selected_pledge?.description)" />
        </InfoSection>
        <ComponentSection title="Donation Preference">
          <InfoRow
            label="Donation Per Tranche"
            :value="checkValueAndLength(data?.selected_pledge?.tranche_amount.toString())"
          />
          <InfoRow label="Number of Tranches" :value="checkValueAndLength(data?.selected_pledge?.periods.toString())" />
          <InfoRow
            label="Tranche Frequency"
            :value="
              checkValueAndLength(
                pledgeTrenchFrequencyOptions.find(option => option.value === data?.selected_pledge?.periodicity)?.title
              )
            "
          />
        </ComponentSection>
        <DisbursementDates
          :disbursements="data?.selected_pledge?.disbursements"
          :disbursement-emails="extractedNames"
          :disbursement-days-before="data?.selected_pledge?.disbursement_days_before"
        />
        <DonationsRecieved :donations="data?.donations_received" :pending-amount="pendingDonationAmount" />
      </template>
      <template #column2>
        <div
          class="flex border rounded bg-white border-zinc-200 justify-between items-center p-3 shadow-[0px_1px_3px_0px_#0000001A,0px_1px_3px_0px_#0000000F]"
        >
          <p class="text-sm font-medium text-zinc-500">Total Amount Pledged</p>
          <p class="text-sm font-medium text-zinc-500">
            {{ formatToIndianCurrency(+data?.selected_pledge.amount) }}
          </p>
        </div>
        <div
          class="flex border rounded bg-white border-zinc-200 justify-between items-center p-3 shadow-[0px_1px_3px_0px_#0000001A,0px_1px_3px_0px_#0000000F]"
        >
          <p class="text-sm font-medium text-zinc-500">Amount Recieved</p>
          <p class="text-sm font-medium text-zinc-500">
            {{ formatToIndianCurrency(+data?.selected_pledge.amount) }}
          </p>
        </div>
        <OverviewCard title="Pledge Quick Links">
          <div class="flex justify-between items-center">
            <div class="flex gap-2 items-center">
              <p
                class="flex h-5 w-5 shrink-0 grow-0 items-center justify-center rounded-full bg-zinc-200 text-zinc-700 xs-medium"
              >
                {{ data?.selected_pledge.donor_id }}
              </p>
              <p class="sm-medium text-zinc-500">Donations</p>
            </div>
            <div class="flex gap-3 items-center">
              <nuxt-link
                :to="{
                  path: '/donations',
                  query: { donor_uid: data?.selected_donor?.uid }
                }"
                class="p-0 border-none shadow-none !bg-transparent text-blue-700"
              >
                View
              </nuxt-link>
              <nuxt-link
                :to="{
                  path: '/donations/add-new-donation',
                  query: { pledgeId: data?.selected_pledge.uid, donorId: data?.selected_donor?.uid }
                }"
                class="p-0 border-none shadow-none !bg-transparent text-blue-700"
              >
                <PlusIcon class="w-5 h-5" />
              </nuxt-link>
            </div>
          </div>
        </OverviewCard>
        <Attachments :items="data?.selected_pledge?.attachment_data" :table="data?.table" />
        <AuditLogs :items="data?.audit_log_data" />
        <AttachmentUpdates :items="data?.attachment_log_data" />
      </template>
    </DrawerLayout>
  </a-drawer>
  <ClosePledgeModal :visible="closePledgeModal" :close="hideClosePledgeModal" :pledge-id="identifier" />
</template>

<style>
  @import '~/assets/css/donationDrawer.css';
</style>
