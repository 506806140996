export const variableMap: { [key: string]: string } = {
  purpose: 'Purpose',
  line_items: 'Line Items',
  enable_grants: 'Enable Grants',
  is_fcra: 'FCRA',
  address: 'Address',
  date: 'Date',
  internal_comments: 'Internal Comments',
  amount: 'Amount',
  description: 'Description',
  dimension: 'Dimenstion',
  name: 'Name',
  donor_type: 'Donor Type',
  pan: 'PAN',
  isd_code: 'ISD Code',
  accounting_date: 'Accounting Date',
  dd_amount_1: 'Donation Amount',
  reporting_emails: 'Reporting Emails',
  disbursement_emails: 'Disbursement Emails',
  end_date: 'End Date',
  signing_date: 'Signing Date',
  start_date: 'Start Date',
  grant_id: 'Grant ID',
  disbursements: 'Disbursements',
  disbursement_days_before: 'Disbursement Days Before',
  cost_centre: 'Cost Centre',
  credit_ledger: 'Credit Ledger',
  debit_ledger: 'Debit Ledger',
  is_active: 'Active',
  approver_notes_1: 'Approver Notes #1',
  approver_notes_2: 'Approver Notes #2',
  approver_notes_3: 'Approver Notes #3',
  approver_notes_4: 'Approver Notes #4',
  approver_notes_5: 'Approver Notes #5',
  approver_notes_6: 'Approver Notes #6',
  approver_notes_7: 'Approver Notes #7',
  approver_notes_8: 'Approver Notes #8',
  approver_notes_9: 'Approver Notes #9',
  approver_notes_10: 'Approver Notes #10',
  approver_status_1: 'Approver Status #1',
  approver_status_2: 'Approver Status #2',
  approver_status_3: 'Approver Status #3',
  approver_status_4: 'Approver Status #4',
  approver_status_5: 'Approver Status #5',
  approver_status_6: 'Approver Status #6',
  approver_status_7: 'Approver Status #7',
  approver_status_8: 'Approver Status #8',
  approver_status_9: 'Approver Status #9',
  approver_status_10: 'Approver Status #10',
  rd_status_1: 'RD Status #1',
  rd_status_2: 'RD Status #2',
  rd_status_3: 'RD Status #3',
  rd_status_4: 'RD Status #4',
  rd_status_5: 'RD Status #5',
  rd_status_6: 'RD Status #6',
  rd_status_7: 'RD Status #7',
  rd_status_8: 'RD Status #8',
  rd_status_9: 'RD Status #9',
  rd_status_10: 'RD Status #10',
  dd_status_1: 'DD Status #1',
  dd_status_2: 'DD Status #2',
  dd_status_3: 'DD Status #3',
  dd_status_4: 'DD Status #4',
  dd_status_5: 'DD Status #5',
  dd_status_6: 'DD Status #6',
  dd_status_7: 'DD Status #7',
  dd_status_8: 'DD Status #8',
  dd_status_9: 'DD Status #9',
  dd_status_10: 'DD Status #10',
  li_subtext_1: 'Line Item Subtext #1',
  li_subtext_2: 'Line Item Subtext #2',
  li_subtext_3: 'Line Item Subtext #3',
  li_subtext_4: 'Line Item Subtext #4',
  li_subtext_5: 'Line Item Subtext #5',
  li_subtext_6: 'Line Item Subtext #6',
  li_subtext_7: 'Line Item Subtext #7',
  li_subtext_8: 'Line Item Subtext #8',
  li_subtext_9: 'Line Item Subtext #9',
  li_subtext_10: 'Line Item Subtext #10',
  li_text_1: 'Line Item Text #1',
  li_text_2: 'Line Item Text #2',
  li_text_3: 'Line Item Text #3',
  li_text_4: 'Line Item Text #4',
  li_text_5: 'Line Item Text #5',
  li_text_6: 'Line Item Text #6',
  li_text_7: 'Line Item Text #7',
  li_text_8: 'Line Item Text #8',
  li_text_9: 'Line Item Text #9',
  li_text_10: 'Line Item Text #10',
  li_amount_1: 'Line Item Amount #1',
  li_amount_2: 'Line Item Amount #2',
  li_amount_3: 'Line Item Amount #3',
  li_amount_4: 'Line Item Amount #4',
  li_amount_5: 'Line Item Amount #5',
  li_amount_6: 'Line Item Amount #6',
  li_amount_7: 'Line Item Amount #7',
  li_amount_8: 'Line Item Amount #8',
  li_amount_9: 'Line Item Amount #9',
  li_amount_10: 'Line Item Amount #10'
};
