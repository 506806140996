<script setup lang="tsx">
  import CustomDropdown from '~/components/UI/Forms/CustomDropdown.vue';
  import CustomInput from '~/components/UI/Forms/CustomInput.vue';
  import CustomTextArea from '~/components/UI/Forms/CustomTextArea.vue';
  import { get, post } from '~/services/donationScreen';
  import type { GetResponse, PurposeDrawer } from '~/types';

  const props = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const route = useRoute();

  const action = computed(() => {
    return route.query.action as string;
  });

  const formRef = ref();
  const formState = reactive({
    purposeName: '',
    purposeCategory: '',
    purposeDimension: '',
    purposeDescription: ''
  });

  const purposeDimesntionsOptions = ref<{ title: string; value: string }[]>([]);

  const fetchData = async () => {
    try {
      const dimensionsResponse = await get<GetResponse<string[]>>(`v1/get_purpose_dimensions`);
      purposeDimesntionsOptions.value = dimensionsResponse.data.data.map(dimension => ({
        title: dimension,
        value: dimension
      }));

      if (props.identifier) {
        const purposeResponse = await get<GetResponse<PurposeDrawer>>(`v1/get_purpose/${props.identifier}`);
        if (purposeResponse.data) {
          Object.assign(formState, {
            purposeName: purposeResponse?.data?.data?.selected_purpose?.purpose_name || '',
            purposeCategory: purposeResponse?.data?.data?.selected_purpose?.purpose_category || '',
            purposeDimension: purposeResponse?.data?.data?.selected_purpose?.purpose_dimension || '',
            purposeDescription: purposeResponse?.data?.data?.selected_purpose?.purpose_description || ''
          });
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const rules = {
    purposeName: [
      {
        required: true,
        message: 'Purpose Name is required',
        trigger: 'submit'
      }
    ],
    purposeCategory: [
      {
        required: true,
        message: 'Purpose Category is required',
        trigger: 'submit'
      }
    ],
    purposeDimension: [
      {
        required: true,
        message: 'Purpose Dimension is required',
        trigger: 'submit'
      }
    ],
    purposeDescription: [
      {
        required: true,
        message: 'Purpose Description is required',
        trigger: 'submit'
      }
    ]
  };

  const updateStateField = (value: string, stateKey: keyof typeof formState) => {
    (formState as any)[stateKey] = value;
  };

  const handleCreatePurpose = async () => {
    try {
      await formRef.value.validate();
      const endpoint = action.value === 'edit' ? `v1/purpose/${props.identifier}` : 'v1/add_new_purpose';
      await post(endpoint, {
        data: {
          purpose_name: formState.purposeName,
          purpose_category: formState.purposeCategory,
          purpose_dimension: formState.purposeDimension,
          purpose_description: formState.purposeDescription
        }
      });
      openSuccessfulCustomNotification('Purpose Created Successfully');
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log(error);
      openFailedCustomNotification('Failed to Create Purpose');
    }
  };

  onMounted(() => {
    fetchData();
  });
</script>

<template>
  <a-drawer
    :open="route.query.drawerFrom === 'purposes'"
    title="Add New Purpose"
    width="750"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    @close="props.onClose"
  >
    <template #extra>
      <a-button
        v-if="!props.identifier || action"
        class="!px-3 !py-2 w-fit h-fit !rounded"
        type="primary"
        @click="handleCreatePurpose"
        >Create Purpose</a-button
      >
    </template>
    <a-form
      ref="formRef"
      class="flex flex-col gap-4 p-3 setup-drawer-form"
      :model="formState"
      :rules="rules"
      :colon="false"
      :label-col="{ style: { width: '250px', textAlign: 'left' } }"
      hide-required-mark="true"
    >
      <CustomInput
        label-text="Purpose Name"
        name="purposeName"
        :value="formState.purposeName"
        state-key="purposeName"
        :is-compulsory="true"
        @update:value="updateStateField"
      />
      <CustomDropdown
        :options="[
          { title: 'Unrestricted', value: 'Unrestricted' },
          { title: 'Restricted', value: 'Restricted' },
          { title: 'Corpus', value: 'Corpus' },
          { title: 'Other Receipts', value: 'Other Receipts' }
        ]"
        label-text="Purpose Category"
        name="purposeCategory"
        :value="formState.purposeCategory"
        state-key="purposeCategory"
        :rule="rules.purposeCategory"
        :is-compulsory="true"
        @update:value="updateStateField"
      />
      <CustomDropdown
        v-if="purposeDimesntionsOptions && purposeDimesntionsOptions.length"
        :options="purposeDimesntionsOptions"
        label-text="Purpose Dimension"
        name="purposeDimension"
        :value="formState.purposeDimension"
        state-key="purposeDimension"
        :rule="rules.purposeDimension"
        :is-compulsory="true"
        @update:value="updateStateField"
      />
      <CustomTextArea
        label="Purpose Description"
        name="purposeDescription"
        :value="formState.purposeDescription"
        state-key="purposeDescription"
        @update:value="updateStateField"
      />
    </a-form>
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';

  .setup-drawer-form .ant-form-item {
    margin-bottom: 0px;
  }
</style>
