<script setup lang="ts">
  import { checkValueAndLength } from '~/functions/stringUtilities';
  import { checkPermission } from '~/utils/auth/buttonController';

  const props = defineProps<{
    componentName: string;
    tooltip?: string;
    onClick: () => void;
    disabled?: boolean;
    activeClass?: string;
    disabledClass?: string;
  }>();

  const route = useRoute();

  const isAllowed = computed(() => checkPermission(route.path, props.componentName));

  const tooltipText = computed(() => isAllowed.value && checkValueAndLength(props.tooltip, null));

  const isDisabled = computed(() => !isAllowed.value || props.disabled);
</script>

<template>
  <a-tooltip
    v-if="isAllowed"
    :title="tooltipText"
    overlay-class-name="text-xs"
    color="#3F3F46"
    :arrow="false"
    placement="bottom"
  >
    <a-button :disabled="isDisabled" :class="`w-fit h-fit p-0 ${activeClass}`" @click="props.onClick">
      <slot></slot>
    </a-button>
  </a-tooltip>
  <a-tooltip
    v-else
    title="Action not Permitted. Contact your Admin to update access."
    overlay-class-name="text-xs"
    color="#3F3F46"
    :arrow="false"
    placement="bottomLeft"
  >
    <a-button :disabled="isDisabled" :class="`w-fit h-fit p-0 !bg-transparent ${disabledClass}`" @click="props.onClick">
      <slot></slot>
    </a-button>
  </a-tooltip>
</template>
