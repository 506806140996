<script lang="ts" setup>
  import AsteriskText from '~/components/shared/Forms/AsteriskText.vue';

  const props = defineProps<{
    value: string | null;
    label: string;
    name: string;
    stateKey: string;
    placeholder?: string;
    rules?: any;
    labelClass?: string;
    textareaClass?: string;
    isCompulsory?: boolean;
    rows?: number;
  }>();

  const localValue = ref<string | null>(props.value);

  const emit = defineEmits(['update:value']);

  watch(
    () => props.value,
    newValue => {
      localValue.value = newValue;
    }
  );

  watch(
    () => localValue.value,
    newValue => {
      emit('update:value', newValue, props.stateKey);
    }
  );
</script>
<template>
  <a-form-item :class="`!font-medium custom-textarea ${labelClass}`" :name="props.name" :rules="props.rules">
    <template #label>
      <AsteriskText v-if="props.isCompulsory" :label="props.label" />
      <p v-else class="text-zinc-600 font-medium">{{ props.label }}</p>
    </template>
    <a-textarea
      v-model:value="localValue"
      :placeholder="props.placeholder || 'Type Something'"
      :rows="props.rows || 2"
      :class="`w-[449px] ${props.textareaClass}`"
    />
  </a-form-item>
</template>

<style>
  .custom-textarea .ant-form-item-label > label {
    line-height: 21px;
  }

  .custom-textarea .ant-input {
    color: #27272a !important;
    padding: 8px !important;
    line-height: 21px !important;
  }
</style>
