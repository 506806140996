<script lang="ts" setup>
  import type { Dayjs } from 'dayjs';
  import AsteriskText from '~/components/shared/Forms/AsteriskText.vue';
  import InformationCircle from '../Others/InformationCircle.vue';

  const props = defineProps<{
    labelText: string;
    value: Date | Dayjs | null | string;
    format?: string;
    stateKey: string;
    name: string;
    rules?: any;
    isCompulsory?: boolean;
    addInfoText?: string;
  }>();

  const localValue = ref<Date | Dayjs | null | string>(props.value);

  const emit = defineEmits(['update:value']);

  watch(
    () => props.value,
    newValue => {
      localValue.value = newValue;
    }
  );

  watch(
    () => localValue.value,
    newValue => {
      emit('update:value', newValue, props.stateKey);
    }
  );
</script>
<template>
  <a-form-item class="!font-medium custom-date-component" :name="props.name" :rules="props.rules">
    <template #label>
      <AsteriskText v-if="props.isCompulsory" :label="props.labelText" />
      <p v-else class="text-zinc-600">{{ props.labelText }}</p>
      <InformationCircle v-if="props.addInfoText" :title="props.addInfoText" class="ml-2" />
    </template>
    <a-date-picker
      v-model:value="localValue"
      :format="props.format || 'DD/MM/YYYY'"
      placeholder=""
      class="w-[248px] p-2 line-height[21px]"
    />
  </a-form-item>
</template>

<style>
  .custom-date-component .ant-form-item-row .ant-form-item-label > label {
    line-height: 21px;
  }

  .custom-date-component #form_item_accountingDate {
    line-height: 21px !important;
    font-weight: 500 !important;
    color: #27272a !important;
  }

  .custom-date-component #form_item_accountingDate-placeholder {
    line-height: 21px !important;
    font-weight: 500 !important;
  }
</style>
