<script setup lang="ts">
  import { checkValueAndLength } from '~/functions/stringUtilities';
  import { checkPermission } from '~/utils/auth/buttonController';

  const props = defineProps<{
    componentName: string;
    tooltip?: string;
    to: string | object;
    activeClass?: string;
    disabledClass?: string;
    disabled?: boolean;
  }>();

  const route = useRoute();

  const isAllowed = computed(() => checkPermission(route.path, props.componentName));

  const tooltipText = computed(() => isAllowed.value && checkValueAndLength(props.tooltip, null));
</script>

<template>
  <a-tooltip
    v-if="isAllowed && !props.disabled"
    :title="tooltipText"
    overlay-class-name="text-xs"
    color="#3F3F46"
    :arrow="false"
    placement="bottomRight"
  >
    <nuxt-link :to="props.to" :class="`w-fit h-fit p-0 ${activeClass}`">
      <slot></slot>
    </nuxt-link>
  </a-tooltip>
  <a-tooltip
    v-else
    title="Action not Permitted. Contact your Admin to update access."
    overlay-class-name="text-xs"
    color="#3F3F46"
    :arrow="false"
    placement="bottomRight"
  >
    <a-button disabled :class="`p-0 w-fit h-fit !bg-transparent ${disabledClass}`">
      <slot></slot>
    </a-button>
  </a-tooltip>
</template>
