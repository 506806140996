<script setup lang="tsx">
  import { MailIcon, PencilIcon, TrashIcon } from '@heroicons/vue/solid';
  import HeaderDisplay from '~/components/UI/Drawers/HeaderDisplay.vue';
  import InfoRow from '~/components/UI/Drawers/InfoRow.vue';
  import InfoSection from '~/components/UI/Drawers/InfoSection.vue';
  import { del, get, post } from '~/services/donationScreen';
  import type { GetResponse, UserInformationDrawer } from '~/types';

  const props = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const route = useRoute();

  const userInformationResponse = ref<UserInformationDrawer>({} as UserInformationDrawer);

  const fetchData = async () => {
    try {
      const response = await get<GetResponse<UserInformationDrawer>>(`v1/get_user/${props.identifier}`);
      userInformationResponse.value = response.data.data;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const sendEmailVerification = async () => {
    try {
      const response = await post<GetResponse<UserInformationDrawer>>(
        `v1/users/resend_verification_link/${props.identifier}`
      );
      if (response.status === 200) {
        openSuccessfulCustomNotification('Email verification sent successfully');
      }
    } catch (err) {
      console.error(err);
      openFailedCustomNotification('Failed to send email verification');
    }
  };

  const deleteUser = async () => {
    try {
      const response = await del(`v1/users/delete_user/${props.identifier}`);
      if (response.status === 200) {
        openSuccessfulCustomNotification('User deleted successfully');
      }
    } catch (err) {
      console.error(err);
      openFailedCustomNotification('Failed to delete user');
    }
  };

  onMounted(() => {
    if (props.identifier) {
      fetchData();
    }
  });
</script>

<template>
  <a-drawer
    :open="route.query.drawerFrom === 'userInformation'"
    title="Add New Stock Item"
    width="750"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    @close="props.onClose"
  >
    <template #extra>
      <div class="flex gap-2">
        <a-button class="!px-2 !py-1.5 w-fit h-fit flex items-center gap-1 !sm-medium" @click="deleteUser">
          <TrashIcon class="w-4 h-4" />
          Delete user
        </a-button>
        <a-button
          :disabled="userInformationResponse.email_verified"
          class="!px-2 !py-1.5 w-fit h-fit flex items-center gap-1 !sm-medium"
          @click="sendEmailVerification"
        >
          <MailIcon class="w-4 h-4" />
          Send Email Verification
        </a-button>
        <nuxt-link :to="`/admin/edit-user?identifier=${props.identifier}`">
          <a-button class="!px-2 !py-1.5 w-fit h-fit flex items-center gap-1 !sm-medium">
            <PencilIcon class="w-4 h-4" />
            Edit User
          </a-button>
        </nuxt-link>
      </div>
    </template>
    <div class="flex flex-col w-full">
      <HeaderDisplay
        :main-display="userInformationResponse.first_name + ' ' + userInformationResponse.last_name"
        :sub-display="userInformationResponse.email"
      />
      <InfoSection>
        <InfoRow label="Email" :value="userInformationResponse.email" />
      </InfoSection>
      <InfoSection title="Permissions">
        <InfoRow label="Role" :value="userInformationResponse.role" />
        <p>These will be dynamically rendered - Prakash</p>
      </InfoSection>
    </div>
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';

  .setup-drawer-form .ant-form-item {
    margin-bottom: 0px;
  }
</style>
