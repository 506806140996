// utils/notification.ts
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/solid';
import { notification } from 'ant-design-vue';

export const openSuccessfullyAddedNotification = (message: string) => {
  notification['success']({
    message: `Successfully added ${message}`,
    class: 'success-notification-card',
    icon: () => h(CheckCircleIcon, { class: '!text-green-700 w-5 h-5' }),
    duration: 3
  });
};

export const openSuccessfullyUpdatedNotification = (message: string) => {
  notification['success']({
    message: `Successfully updated ${message}`,
    class: 'success-notification-card',
    icon: () => h(CheckCircleIcon, { class: '!text-green-700 w-5 h-5' }),
    duration: 3
  });
};

export const openSuccessfullyDeletedNotification = (message: string) => {
  notification['success']({
    message: `Successfully deleted ${message}`,
    class: 'success-notification-card',
    icon: () => h(CheckCircleIcon, { class: '!text-green-700 w-5 h-5' }),
    duration: 3
  });
};

export const openSuccessfulCustomNotification = (message: string, description?: string) => {
  notification['success']({
    message: message,
    description: description,
    class: 'success-notification-card',
    icon: () => h(CheckCircleIcon, { class: '!text-green-700 w-5 h-5' }),
    duration: 3
  });
};

export const openFailedCustomNotification = (message: string) => {
  notification['error']({
    message: message,
    class: 'success-notification-card',
    icon: () => h(ExclamationCircleIcon, { class: 'text-red-700 w-5 h-5' }),
    duration: 3
  });
};

export const openWarningCustomNotification = (message: string) => {
  notification['warning']({
    message: message,
    class: 'success-notification-card',
    icon: () => h(ExclamationCircleIcon, { class: 'text-yellow-700 w-5 h-5' }),
    duration: 3
  });
};

export const openFailedAdditionNotification = (message: string) => {
  notification['error']({
    message: `Some error occured while adding ${message}`,
    description: () =>
      h('div', { class: 'custom-description' }, [
        h('p', 'Please take the screenshot and send to support'),
        h(
          'a',
          { href: 'https://erp4impact.freshdesk.com/support/tickets/new', target: '_blank' },
          'Report to the support team'
        )
      ]),
    class: 'failed-notification-card',
    icon: () => h(ExclamationCircleIcon, { class: 'text-red-700 w-5 h-5' }),
    duration: 5
  });
};

export const openFailedUpdationNotification = (message: string) => {
  notification['error']({
    message: `Some error occured while updating ${message}`,
    description: () =>
      h('div', { class: 'custom-description' }, [
        h('p', 'Please take the screenshot and send to support'),
        h(
          'a',
          { href: 'https://erp4impact.freshdesk.com/support/tickets/new', target: '_blank' },
          'Report to the support team'
        )
      ]),
    class: 'failed-notification-card',
    icon: () => h(ExclamationCircleIcon, { class: 'text-red-700 w-5 h-5' }),
    duration: 5
  });
};

export const openFailedDeletionNotification = (message: string) => {
  notification['error']({
    message: `Some error occured while deleting ${message}`,
    description: () =>
      h('div', { class: 'custom-description' }, [
        h('p', 'Please take the screenshot and send to support'),
        h(
          'a',
          { href: 'https://erp4impact.freshdesk.com/support/tickets/new', target: '_blank' },
          'Report to the support team'
        )
      ]),
    class: 'failed-notification-card',
    icon: () => h(ExclamationCircleIcon, { class: 'text-red-700 w-5 h-5' }),
    duration: 5
  });
};

export const openFailedWithScreenshotNotification = (message: string) => {
  notification['error']({
    message: message,
    description: () =>
      h('div', { class: 'custom-description' }, [
        h('p', 'Please take the screenshot and send to support'),
        h(
          'a',
          { href: 'https://erp4impact.freshdesk.com/support/tickets/new', target: '_blank' },
          'Report to the support team'
        )
      ]),
    class: 'failed-notification-card',
    icon: () => h(ExclamationCircleIcon, { class: 'text-red-700 w-5 h-5' }),
    duration: 5
  });
};
