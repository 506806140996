<script setup lang="tsx">
  import { PencilIcon, PlusIcon } from '@heroicons/vue/solid';
  import { get } from '~/services/donationScreen';
  import type { GrantsDrawerType, ResponseStatus } from '~/types';
  import InfoRow from '~/components/UI/Drawers/InfoRow.vue';
  import InfoSection from '~/components/UI/Drawers/InfoSection.vue';
  import DrawerLayout from '~/components/UI/Drawers/DrawerLayout.vue';
  import { checkValueAndLength } from '~/functions/stringUtilities';
  import RoleBasedLinkButton from '~/components/UI/Others/RoleBasedLinkButton.vue';
  import ComponentSectionRow from '~/components/UI/Drawers/ComponentSectionRow.vue';
  import Attachments from '../../Others/Attachments.vue';
  import AuditLogs from '../RoutedDrawerComponents/AuditLogs.vue';
  import AttachmentUpdates from '../RoutedDrawerComponents/AttachmentUpdates.vue';
  import { formatDate } from '~/functions/dateTimeUtilities';
  import { formatToIndianCurrency } from '~/functions/currencyUtilities';
  import GrantLineItem from '../RoutedDrawerComponents/GrantLineItem.vue';
  import DisbursementDates from '../RoutedDrawerComponents/DisbursementDates.vue';
  import DonationsRecieved from '../RoutedDrawerComponents/DonationsRecieved.vue';
  import ReportDates from '../RoutedDrawerComponents/ReportDates.vue';
  import OverviewCard from '~/components/UI/Others/OverviewCard.vue';
  import ExpensesStatus from '../../Others/ExpensesStatus.vue';
  import RoleBasedButton from '~/components/UI/Others/RoleBasedButton.vue';
  import CloseGrantModal from '../../Modals/CloseGrantModal.vue';

  const { onClose, identifier } = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const closeGrantModal = ref<boolean>(false);

  const data = ref<GrantsDrawerType>({} as GrantsDrawerType);

  const responseState = ref<ResponseStatus>('idle');

  const pendingDonationAmount = computed(() => {
    if (!data.value?.selected_grant?.amount || !data.value?.donations_against_grant) return 0;

    const totalReceived = data.value.donations_against_grant.reduce((acc, curr) => acc + +curr.amount, 0);
    return data.value.selected_grant.amount - totalReceived;
  });

  const extractedNames = computed(() => {
    if (!data.value?.selected_grant?.grant_members) return [];

    return data.value?.selected_grant?.grant_members
      ?.split(',')
      .filter((entry: string) => entry.trim()) // Remove empty entries
      .map((entry: string) => {
        // Split by underscore and get everything before the email
        const nameParts = entry.trim().split('_');
        // Remove the email part (last element)
        nameParts.pop();
        // Join the remaining parts with spaces
        return nameParts.join(' ');
      });
  });

  const showCloseGrantModal = () => {
    closeGrantModal.value = true;
  };

  const hideCloseGrantModal = () => {
    closeGrantModal.value = false;
    fetchData(); // Refresh data after closing modal
  };

  const fetchData = async () => {
    responseState.value = 'pending';
    try {
      const response = await get<GrantsDrawerType>(`v1/get_grant/${identifier}`);
      if (response.status === 200) {
        data.value = response.data;
        responseState.value = 'success';
      } else {
        responseState.value = 'error';
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  onMounted(fetchData);
</script>

<template>
  <a-drawer
    v-if="identifier"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    title="Grant Information"
    :open="identifier !== null"
    width="1200"
    @close="onClose"
  >
    <template #extra>
      <div v-if="!data?.selected_grant?.is_closed" class="flex gap-2">
        <RoleBasedLinkButton
          :to="{ path: '/grants/edit-grant', query: { identifier } }"
          component-name="editActionButton"
          active-class="text-blue-700 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center text-gray-500 border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          disabled-class="flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
        >
          <PencilIcon class="h-4 w-4" />
          Edit Grant
        </RoleBasedLinkButton>
        <RoleBasedButton
          component-name="closeActionButton"
          active-class="text-blue-700 flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center text-gray-500 border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          disabled-class="flex rounded items-center gap-1 text-sm font-medium py-1.5 px-2 justify-center border border-gray-300 shadow-[0_1px_2px_0px_#0000000D]"
          @click="showCloseGrantModal"
        >
          Close Grant
        </RoleBasedButton>
      </div>
      <p v-else class="text-base font-bold text-red-600">Grant is Closed</p>
    </template>
    <DrawerLayout
      :response-state="responseState"
      :main-display="checkValueAndLength(data?.selected_donor?.name) || '-'"
      :sub-display="checkValueAndLength(data?.selected_grant?.grant_id) || '-'"
      :side-display="formatToIndianCurrency(data?.selected_grant?.amount)"
      @close="onClose"
    >
      <template #column1>
        <InfoSection>
          <InfoRow label="Donor" :value="checkValueAndLength(data?.selected_donor?.name)" />
          <InfoRow
            :label="checkValueAndLength(data?.selected_donor?.pan_alternate_id?.id_type, 'PAN') || '-'"
            :value="checkValueAndLength(data?.selected_donor?.pan_alternate_id?.id_ref)"
          />
          <InfoRow label="Grant ID" :value="checkValueAndLength(data?.selected_grant?.grant_id)" />
          <InfoRow label="Grant Signing Date" :value="formatDate(data?.selected_grant?.signing_date)" />
          <InfoRow label="Grant End Date" :value="formatDate(data?.selected_grant?.end_date)" />
          <InfoRow label="Description" :value="checkValueAndLength(data?.selected_grant?.description)" />
        </InfoSection>
        <InfoSection>
          <InfoRow label="Purpose" :value="checkValueAndLength(data?.selected_grant?.purpose)" />
          <InfoRow label="Grant Amount" :value="formatToIndianCurrency(data?.selected_grant?.amount)" />
        </InfoSection>
        <div class="flex px-3">
          <ComponentSectionRow label="Grant Members" class="border-t py-3">
            <div class="flex gap-3 flex-wrap">
              <p v-for="name in extractedNames" :key="name" class="bg-zinc-100 px-1 py-0.5 rounded-md">
                {{ name }}
              </p>
            </div>
          </ComponentSectionRow>
        </div>
        <GrantLineItem :items="data?.selected_grant?.line_items" />
        <DisbursementDates
          :disbursements="data?.selected_grant?.disbursements"
          :disbursement-emails="data?.selected_grant?.disbursement_emails"
          :disbursement-days-before="data?.selected_grant?.disbursement_days_before"
        />
        <DonationsRecieved :donations="data?.donations_against_grant" :pending-amount="pendingDonationAmount" />
        <ReportDates
          :reports="data?.selected_grant?.reports"
          :reporting-emails="data?.selected_grant?.reporting_emails"
          :reporting-days-before="data?.selected_grant?.reporting_days_before"
        />
      </template>
      <template #column2>
        <div
          class="flex flex-col border rounded bg-white border-zinc-200 shadow-[0px_1px_3px_0px_#0000001A,0px_1px_3px_0px_#0000000F]"
        >
          <div class="flex justify-between items-center p-3 border-b">
            <p class="text-sm font-medium text-zinc-500">Total Grant Amount</p>
            <p class="text-sm font-medium text-zinc-500">
              {{ formatToIndianCurrency(data?.selected_grant.amount) }}
            </p>
          </div>
          <div class="flex justify-between items-center px-3 py-2 border-b">
            <p class="text-sm font-medium text-zinc-500">Total Received</p>
            <p class="text-sm font-medium text-zinc-500">
              {{ formatToIndianCurrency(data?.donations_against_grant.reduce((acc, curr) => acc + +curr.amount, 0)) }}
            </p>
          </div>
          <div class="flex justify-between items-center px-3 py-2 border-b">
            <p class="text-sm font-medium text-zinc-500">Total Utilized</p>
            <p class="text-sm font-medium text-zinc-500">
              {{
                formatToIndianCurrency(
                  data?.stats.approved_expenses.exceeding_grant + data?.stats.approved_expenses.within_grant
                )
              }}
            </p>
          </div>
          <div class="flex justify-between items-center p-3">
            <ExpensesStatus :stats="data?.stats" />
          </div>
        </div>
        <OverviewCard title="Grant Quick Links">
          <div class="flex justify-between items-center">
            <div class="flex gap-2 items-center">
              <p
                class="flex h-5 w-5 shrink-0 grow-0 items-center justify-center rounded-full bg-zinc-200 text-zinc-700 xs-medium"
              >
                {{ data?.selected_grant.donor_id }}
              </p>
              <p class="sm-medium text-zinc-500">Donations</p>
            </div>
            <div class="flex gap-3 items-center">
              <nuxt-link
                :to="{
                  path: '/donations',
                  query: { donor_uid: data?.selected_donor?.uid }
                }"
                class="p-0 border-none shadow-none !bg-transparent text-blue-700"
              >
                View
              </nuxt-link>
              <nuxt-link
                :to="{
                  path: '/donations/add-new-donation',
                  query: { grantId: data?.selected_grant.grant_id, donorId: data?.selected_donor?.uid }
                }"
                class="p-0 border-none shadow-none !bg-transparent text-blue-700"
              >
                <PlusIcon class="w-5 h-5" />
              </nuxt-link>
            </div>
          </div>
          <div class="flex justify-between items-center">
            <div class="flex gap-2 items-center">
              <p
                class="flex h-5 w-5 shrink-0 grow-0 items-center justify-center rounded-full bg-zinc-200 text-zinc-700 xs-medium"
              >
                {{ data?.selected_grant.donor_id }}
              </p>
              <p class="sm-medium text-zinc-500">Expenses</p>
            </div>
            <div class="flex gap-3 items-center">
              <nuxt-link
                :to="{
                  path: '/expenses',
                  query: { grant_id: data?.selected_grant.grant_id, donor_id: data?.selected_donor?.uid }
                }"
                class="p-0 border-none shadow-none !bg-transparent text-blue-700"
              >
                View
              </nuxt-link>
              <a-button disabled class="p-0 border-none shadow-none !bg-transparent text-blue-700">
                <PlusIcon class="w-5 h-5" />
              </a-button>
            </div>
          </div>
        </OverviewCard>
        <Attachments :items="data?.selected_grant?.attachments_data" :table="data?.selected_grant?.table" />
        <AuditLogs :items="data?.audit_log_data" />
        <AttachmentUpdates :items="data?.attachment_log_data" />
      </template>
    </DrawerLayout>
  </a-drawer>
  <CloseGrantModal :visible="closeGrantModal" :close="hideCloseGrantModal" :grant-id="identifier" />
</template>

<style>
  @import '~/assets/css/donationDrawer.css';
</style>
