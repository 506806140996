<script setup lang="tsx">
  import MonetaryDonationDrawer from './RoutedDrawerCollection/MonetaryDonationDrawer.vue';
  import InKindDonationDrawer from './RoutedDrawerCollection/InKindDonationDrawer.vue';
  import DonorsDrawer from './RoutedDrawerCollection/DonorsDrawer.vue';
  import AllGrantsDrawer from './RoutedDrawerCollection/AllGrantsDrawer.vue';
  import AllPledgesDrawer from './RoutedDrawerCollection/AllPledgesDrawer.vue';
  import AllBudgetDrawer from './RoutedDrawerCollection/AllBudgetDrawer.vue';
  import ContactTypeDrawer from './RoutedDrawerCollection/ContactTypeDrawer.vue';
  import AccountingPeriodDrawer from './RoutedDrawerCollection/AccountingPeriodDrawer.vue';
  import PurposeDrawer from './RoutedDrawerCollection/PurposeDrawer.vue';
  import StockItemDrawer from './RoutedDrawerCollection/StockItemDrawer.vue';
  import LedgerDrawer from './RoutedDrawerCollection/LedgerDrawer.vue';
  import CostCentreDrawer from './RoutedDrawerCollection/CostCentreDrawer.vue';
  import UserInformationDrawer from './RoutedDrawerCollection/UserInformationDrawer.vue';
  import VendorDrawer from './RoutedDrawerCollection/VendorDrawer.vue';

  const identifier = ref<string | null>(null);
  const drawerFrom = ref<string | null>(null);

  const route = useRoute();
  const router = useRouter();

  const onClose = () => {
    const { drawerFrom, identifier, action, ...rest } = route.query;
    router.replace({ query: { ...rest } });
  };

  const openModal = (modalType: string, identifier: string) => {
    const { drawerFrom, ...restQuery } = route.query;
    router.replace({
      query: {
        ...restQuery,
        modalFor: modalType,
        identifier: identifier
      }
    });
  };

  watch(
    () => route.query,
    newQuery => {
      if (newQuery.drawerFrom) {
        drawerFrom.value = newQuery.drawerFrom as string;
        identifier.value = (newQuery.identifier as string) || null;
      } else {
        identifier.value = null;
        drawerFrom.value = null;
      }
    },
    { immediate: true, deep: true }
  );
</script>

<template>
  <MonetaryDonationDrawer
    v-if="drawerFrom === 'monetaryDonations'"
    :open-modal="openModal"
    :identifier="identifier || ''"
    @close="onClose"
  />
  <InKindDonationDrawer
    v-if="drawerFrom === 'inKindDonations'"
    :open-modal="openModal"
    :identifier="identifier || ''"
    @close="onClose"
  />
  <DonorsDrawer
    v-if="drawerFrom === 'donors'"
    :open-modal="openModal"
    :identifier="identifier || ''"
    @close="onClose"
  />
  <VendorDrawer
    v-if="drawerFrom === 'vendors'"
    :open-modal="openModal"
    :identifier="identifier || ''"
    @close="onClose"
  />
  <AllGrantsDrawer
    v-if="drawerFrom === 'allGrants'"
    :open-modal="openModal"
    :identifier="identifier || ''"
    @close="onClose"
  />
  <AllPledgesDrawer
    v-if="drawerFrom === 'allPledges'"
    :open-modal="openModal"
    :identifier="identifier || ''"
    @close="onClose"
  />
  <AllBudgetDrawer
    v-if="drawerFrom === 'allBudgets'"
    :open-modal="openModal"
    :identifier="identifier || ''"
    @close="onClose"
  />
  <ContactTypeDrawer v-if="drawerFrom === 'contactTypes'" :identifier="identifier || ''" @close="onClose" />
  <AccountingPeriodDrawer v-if="drawerFrom === 'accountingPeriods'" :identifier="identifier || ''" @close="onClose" />
  <PurposeDrawer v-if="drawerFrom === 'purposes'" :identifier="identifier || ''" @close="onClose" />
  <StockItemDrawer v-if="drawerFrom === 'stockItems'" :identifier="identifier || ''" @close="onClose" />
  <LedgerDrawer v-if="drawerFrom === 'ledgers'" :identifier="identifier || ''" @close="onClose" />
  <CostCentreDrawer v-if="drawerFrom === 'costCentres'" :identifier="identifier || ''" @close="onClose" />
  <UserInformationDrawer v-if="drawerFrom === 'userInformation'" :identifier="identifier || ''" @close="onClose" />
</template>
