<script setup lang="ts">
  import ComponentSection from '~/components/UI/Drawers/ComponentSection.vue';
  import ComponentSectionRow from '~/components/UI/Drawers/ComponentSectionRow.vue';
  import { formatToIndianCurrency } from '~/functions/currencyUtilities';
  import { formatDate } from '~/functions/dateTimeUtilities';
  import type { GrantsDrawerType } from '~/types';

  const props = defineProps<{
    disbursements: GrantsDrawerType['selected_grant']['disbursements'];
    disbursementEmails: GrantsDrawerType['selected_grant']['disbursement_emails'];
    disbursementDaysBefore: GrantsDrawerType['selected_grant']['disbursement_days_before'];
  }>();

  const emails = computed(() => {
    if (!props.disbursementEmails) return [];
    return props.disbursementEmails.split(',').map(email => email.trim());
  });
</script>

<template>
  <ComponentSection title="Disbursement Dates">
    <ComponentSectionRow label="Reminder Email List">
      <div v-if="emails.length > 0" class="flex gap-3 flex-wrap">
        <p v-for="email in emails" :key="email" class="bg-zinc-100 px-1 py-0.5 rounded-md">{{ email }}</p>
      </div>
      <p v-else>-</p>
    </ComponentSectionRow>
    <ComponentSectionRow label="Reminder Before">
      <a-input-number disabled :value="props.disbursementDaysBefore">
        <template #addonAfter>
          <p>Days</p>
        </template>
      </a-input-number>
    </ComponentSectionRow>
    <div class="border border-zinc-300 rounded overflow-hidden">
      <table class="w-full border-collapse sm-medium rounded">
        <thead>
          <tr class="border-b bg-zinc-50">
            <th class="text-left py-2 px-3 border-r font-medium">Sr</th>
            <th class="text-left py-2 px-3 border-x font-medium">Date</th>
            <th class="text-left py-2 px-3 border-x font-medium">Amount</th>
            <th class="text-left py-2 px-3 border-l font-medium">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(disbursement, index) in props.disbursements" :key="disbursement.dd_date">
            <td class="py-2 px-3 border-t border-r">{{ index + 1 }}</td>
            <td class="py-2 px-3 border-t border-r">{{ formatDate(disbursement.dd_date, 'DD/MM/YYYY') }}</td>
            <td class="py-2 px-3 border-x border-t">{{ formatToIndianCurrency(+disbursement.dd_amount) }}</td>
            <td class="py-2 px-3 border-t border-l">{{ disbursement.dd_status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ComponentSection>
</template>
