import { createEmailList } from '~/components/Grants/grantsFormUtils';
import { toDateTime } from '~/functions/dateTimeUtilities';
import type { SelectedDonorFromAPI } from '~/types';
import type { AddNewPledgeFormState, PledgeDisbursementDate, PledgeReminderUserFromAPI } from '~/types/pledges';

export const addNewPledgeBreadCrumb: { route: string; title: string }[] = [
  { route: '/pledges', title: 'Pledges' },
  { route: '/pledges/add-new-pledge', title: 'New Pledge' }
];

export enum PledgeTrenchFrequency {
  PLEDGE_LETTER = 'Pledge Letter',
  OTHER = 'Other'
}

export const pledgeTrenchFrequencyOptions = [
  { title: 'Monthly', value: 1 },
  { title: 'Bi-Monthly', value: 2 },
  { title: 'Quarterly', value: 3 },
  { title: 'Annually', value: 12 }
];

export const pledgeFormDefaultState: AddNewPledgeFormState = {
  donorName: '',
  donorUid: null,
  purpose: '',
  pledgeId: '',
  pledgeStartDate: null,
  pledgeDescription: '',
  donationPerTrench: null,
  numberOfTrenches: 0,
  trenchFrequency: null,
  reminderUserList: [],
  reminderBeforeDays: 0,
  pledgeAmount: null,
  attachments: [],
  disbursementDates: []
};

export const newPledgeRules: {
  [key: string]: { required: boolean; message: string; trigger: string }[];
} = {
  donorName: [
    {
      required: true,
      message: 'Donor name is required!',
      trigger: 'submit'
    }
  ],
  pledgeId: [
    {
      required: true,
      message: 'Pledge ID is required!',
      trigger: 'submit'
    }
  ],
  pledgeStartDate: [
    {
      required: true,
      message: 'Pledge start date is required!',
      trigger: 'submit'
    }
  ],
  donationPerTrench: [
    {
      required: true,
      message: 'Donation per trench is required!',
      trigger: 'submit'
    }
  ],
  numberOfTrenches: [
    {
      required: true,
      message: 'Number of trenches is required!',
      trigger: 'submit'
    }
  ],
  trenchFrequency: [
    {
      required: true,
      message: 'Trench frequency is required!',
      trigger: 'submit'
    }
  ],
  reminderUserList: [
    {
      required: true,
      message: 'Reminder user list is required!',
      trigger: 'submit'
    }
  ],
  reminderBeforeDays: [
    {
      required: true,
      message: 'Reminder before days is required!',
      trigger: 'submit'
    }
  ]
};

const disbursementDateData = (disbursementDates: PledgeDisbursementDate[]) => {
  const formattedData: Record<string, any> = {};
  disbursementDates.forEach((disbursement, index) => {
    const itemNumber = index + 1;
    formattedData[itemNumber] = {
      [`dd_date_${itemNumber}`]: new Date(disbursement.dueDate as Date).toISOString(),
      [`dd_amount_${itemNumber}`]: disbursement.amount?.toString(),
      [`dd_status_${itemNumber}`]: disbursement.status
    };
  });
  return JSON.stringify(formattedData).toString();
};

export const createPledgeFormData = (formState: AddNewPledgeFormState) => {
  const formData: any = {
    donor_id: formState.donorUid?.toString() || '',
    pledge_id: formState.pledgeId,
    purpose: formState.purpose,
    start_date: formState.pledgeStartDate?.toISOString(),
    description: formState.pledgeDescription,
    tranche_amount: formState.donationPerTrench?.toString(),
    periods: formState.numberOfTrenches,
    periodicity: formState.trenchFrequency,
    disbursement_emails: createEmailList(formState.reminderUserList),
    disbursement_days_before: formState.reminderBeforeDays,
    disbursements: disbursementDateData(formState.disbursementDates)
  };

  formState.attachments.forEach((file, index) => {
    if (file.rawFile) {
      formData[`at_file_${index + 1}`] = file.rawFile;
      formData[`at_cat_${index + 1}`] = file.category;
    }
  });
  return formData;
};

export const createPledgeFormState = (
  formData: any,
  selectedDonor: SelectedDonorFromAPI,
  reminderUserList: PledgeReminderUserFromAPI[]
) => {
  const formState: AddNewPledgeFormState = {
    donorName: selectedDonor.name,
    donorUid: selectedDonor.uid,
    purpose: formData.purpose,
    pledgeId: formData.pledge_id,
    pledgeStartDate: toDateTime(formData.start_date),
    pledgeDescription: formData.description,
    donationPerTrench: formData.tranche_amount,
    numberOfTrenches: formData.periods,
    trenchFrequency: formData.periodicity,
    reminderBeforeDays: formData.disbursement_days_before,
    attachments: formData.attachment_data?.map((attachment: any, index: number) => ({
      key: Date.now() + index,
      filename: attachment.filename
    })),
    pledgeAmount: +formData.amount,
    reminderUserList: formData.disbursement_emails
      ? formData.disbursement_emails
          .split(', ')
          .filter(Boolean)
          .map((email: string) => {
            const user = reminderUserList.find(user => user.email === email);
            return {
              firstName: user?.first_name || '',
              lastName: user?.last_name || '',
              email: user?.email || ''
            };
          })
      : [],
    disbursementDates: formData.disbursements.map((disbursement: any) => ({
      dueDate: toDateTime(disbursement.dd_date),
      amount: disbursement.dd_amount,
      status: disbursement.dd_status
    }))
  };
  return formState;
};
