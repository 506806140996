import { useAuth } from '~/composables/useAuth';
import { Role } from '~/types/auth';

const routeAccess = new Map([
  [
    'dashboard',
    {
      routes: ['/'],
      roles: [
        Role.Admin,
        Role.GrantReportManager,
        Role.ExpenseReportManager,
        Role.BudgetReportManager,
        Role.DonorReportManager,
        Role.ExpenseApprover,
        Role.ExpenseAdmin,
        Role.ReadOnly,
        Role.Auditor,
        Role.DonorAuditor
      ]
    }
  ],
  [
    'expenses',
    {
      routes: ['/expenses', '/add-new-expense', '/edit-expense'],
      roles: [
        Role.Admin,
        Role.ReadOnly,
        Role.ExpenseSubmitter,
        Role.ExpenseApprover,
        Role.BudgetApprover,
        Role.BudgetMember,
        Role.Auditor,
        Role.DonorAuditor
      ]
    }
  ],
  [
    'advances',
    {
      routes: ['/advances', '/request-advance', '/approve-advance'],
      roles: [
        Role.Admin,
        Role.ReadOnly,
        Role.ExpenseSubmitter,
        Role.ExpenseApprover,
        Role.BudgetApprover,
        Role.BudgetMember,
        Role.Auditor,
        Role.DonorAuditor
      ]
    }
  ],
  [
    'vendors',
    {
      routes: ['/vendors', '/vendors/add-new-vendor', '/vendors/edit-vendor'],
      roles: [Role.Admin, Role.DonorManager, Role.ReadOnly, Role.DonationManager, Role.Auditor, Role.DonorAuditor]
    }
  ],
  [
    'donors',
    {
      routes: ['/donors'],
      roles: [Role.Admin, Role.DonorManager, Role.ReadOnly, Role.DonationManager, Role.Auditor]
    }
  ],
  [
    'addAndEditDonor',
    {
      routes: ['/donors/add-new-donor', '/donors/edit-donor'],
      roles: [Role.Admin, Role.DonorManager]
    }
  ],
  [
    'donations',
    {
      routes: ['/donations'],
      roles: [Role.Admin, Role.DonationManager, Role.DonorManager, Role.ReadOnly, Role.Auditor]
    }
  ],
  [
    'addAndEditNewDonation',
    {
      routes: ['/donations/add-new-donation', '/donations/edit-donation'],
      roles: [Role.Admin, Role.DonationManager]
    }
  ],
  [
    'grants',
    {
      routes: ['/grants'],
      roles: [Role.Admin, Role.GrantSubmitter, Role.GrantMember, Role.ReadOnly, Role.Auditor, Role.DonorAuditor]
    }
  ],
  [
    'addNewGrant',
    {
      routes: ['/grants/add-new-grant'],
      roles: [Role.Admin, Role.GrantSubmitter]
    }
  ],
  [
    'editGrant',
    {
      routes: ['/grants/edit-grant'],
      roles: [Role.Admin, Role.GrantSubmitter, Role.GrantMember]
    }
  ],
  [
    'pledges',
    {
      routes: ['/pledges', '/pledges/add-new-pledge', '/pledges/edit-pledge'],
      roles: [Role.Admin, Role.DonationManager, Role.DonorManager]
    }
  ],
  [
    'budgets',
    {
      routes: ['/budgets'],
      roles: [
        Role.Admin,
        Role.ReadOnly,
        Role.ExpenseSubmitter,
        Role.ExpenseApprover,
        Role.BudgetApprover,
        Role.BudgetMember,
        Role.Auditor
      ]
    }
  ],
  [
    'addAndEditNewBudget',
    {
      routes: ['/budgets/add-new-budget', '/budgets/edit-budget'],
      roles: [Role.Admin, Role.ExpenseApprover, Role.ExpenseSubmitter, Role.BudgetApprover, Role.BudgetMember]
    }
  ],
  [
    'utilities',
    {
      routes: [
        '/utilities',
        '/utilities/import_payments',
        '/utilities/import_ledgers',
        '/utilities/statutory_reports',
        '/utilities/download_formats',
        '/utilities/10be_emailer',
        '/utilities/export_tally_income',
        '/utilities/export_tally_expense',
        '/utilities/expense_reconciliation',
        '/utilities/expense_reconciliation_status'
      ],
      roles: [Role.Admin, Role.UtilitiesAccess]
    }
  ],
  [
    'admin',
    {
      routes: ['/admin', '/admin/add_new_user', '/admin/edit_user', '/admin/users_list', '/admin/audit_logs_list'],
      roles: [Role.Admin]
    }
  ],
  [
    'setup',
    {
      routes: [
        '/setup',
        '/setup/cost_centres',
        '/setup/ledgers',
        '/setup/accounting_periods',
        '/setup/purposes',
        '/setup/stock_items'
      ],
      roles: [Role.Admin]
    }
  ]
]);

export const canAccessRoute = (route: string): boolean => {
  const { role } = useAuth();

  for (const accessGroup of routeAccess.values()) {
    if (accessGroup.routes.includes(route)) {
      return accessGroup.roles.some(requiredRole => role.value.includes(requiredRole));
    }
  }
  return false;
};
