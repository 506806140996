<script setup lang="tsx">
  import CustomDate from '~/components/UI/Forms/CustomDate.vue';
  import CustomDropdown from '~/components/UI/Forms/CustomDropdown.vue';
  import CustomInput from '~/components/UI/Forms/CustomInput.vue';
  import { getToday, toDateTime } from '~/functions/dateTimeUtilities';
  import { get, post } from '~/services/donationScreen';
  import type { AccountingPeriodDrawer, GetResponse, ResponseStatus } from '~/types';
  import AuditLogs from '../RoutedDrawerComponents/AuditLogs.vue';

  const props = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const route = useRoute();

  const action = computed(() => {
    return route.query.action as string;
  });

  const formRef = ref();
  const formState = reactive({
    startDate: getToday(),
    eightyGSerialNumber: '-',
    regularSerialNumber: '-',
    openStatus: 'Open'
  });

  const responseState = ref<ResponseStatus>('idle');
  const data = ref<AccountingPeriodDrawer>({} as AccountingPeriodDrawer);

  const fetchData = async () => {
    responseState.value = 'pending';
    try {
      const response = await get<GetResponse<AccountingPeriodDrawer>>(`v1/get_acc_period/${props.identifier}`);
      if (response.status === 200) {
        data.value = response.data.data;
        Object.assign(formState, {
          startDate: toDateTime(response?.data?.data?.selected_period?.year_start_date) || '',
          eightyGSerialNumber: response?.data?.data?.selected_period?.serial_80g?.toString() || '',
          regularSerialNumber: response?.data?.data?.selected_period?.serial_reg?.toString() || '',
          openStatus: response?.data?.data?.selected_period?.is_open || ''
        });
        responseState.value = 'success';
      } else {
        responseState.value = 'error';
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      responseState.value = 'error';
    }
  };

  const rules = {
    startDate: [
      {
        required: true,
        message: 'Accounting Start Date is required',
        trigger: 'submit'
      }
    ],
    eightyGSerialNumber: [
      {
        required: true,
        message: '80G Serial Number is required',
        trigger: 'submit'
      }
    ],
    regularSerialNumber: [
      {
        required: true,
        message: 'Regular Serial Number is required',
        trigger: 'submit'
      }
    ],
    openStatus: [
      {
        required: true,
        message: 'Open Status is required',
        trigger: 'submit'
      }
    ]
  };

  const updateStateField = (value: string, stateKey: keyof typeof formState) => {
    (formState as any)[stateKey] = value;
  };

  const handleCreateAccountingPeriod = async () => {
    try {
      await formRef.value.validate();
      await post('/v1/add_new_acc_period', { data: { year_start_date: formState.startDate } });
      openSuccessfulCustomNotification('Accounting Period Created Successfully');
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log(error);
      openFailedCustomNotification('Failed to Create Accounting Period');
    }
  };

  onMounted(() => {
    if (props.identifier) {
      fetchData();
    }
  });
</script>

<template>
  <a-drawer
    :open="route.query.drawerFrom === 'accountingPeriods'"
    title="Add New Accounting Period"
    width="750"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    @close="props.onClose"
  >
    <template #extra>
      <a-button
        v-if="!props.identifier || action"
        class="!px-3 !py-2 w-fit h-fit !rounded"
        type="primary"
        @click="handleCreateAccountingPeriod"
        >Create New Period</a-button
      >
    </template>
    <a-form
      ref="formRef"
      class="flex flex-col gap-4 p-3 setup-drawer-form"
      :model="formState"
      :rules="rules"
      :colon="false"
      :label-col="{ style: { width: '250px', textAlign: 'left' } }"
      hide-required-mark="true"
    >
      <CustomDate
        label-text="Accounting Start Date"
        name="startDate"
        :value="toDateTime(formState.startDate)"
        state-key="startDate"
        :is-compulsory="true"
        :rule="rules.startDate"
      />
      <CustomInput
        label-text="Last 80G Serial Number"
        name="eightyGSerialNumber"
        :value="formState.eightyGSerialNumber"
        state-key="eightyGSerialNumber"
        :is-compulsory="true"
        :rule="rules.eightyGSerialNumber"
        :is-disabled="true"
        @update:value="updateStateField"
      />
      <CustomInput
        label-text="Last Regular Serial Number"
        name="regularSerialNumber"
        :value="formState.regularSerialNumber"
        state-key="regularSerialNumber"
        :is-compulsory="true"
        :rule="rules.regularSerialNumber"
        :is-disabled="true"
        @update:value="updateStateField"
      />
      <CustomDropdown
        :options="[
          { title: 'Open', value: 'Open' },
          { title: 'Closed', value: 'Closed' }
        ]"
        label-text="Open Status"
        name="openStatus"
        :value="formState.openStatus"
        state-key="openStatus"
        :is-compulsory="true"
        :rule="rules.openStatus"
        :disabled="true"
        @update:value="updateStateField"
      />
      <AuditLogs v-if="responseState === 'success'" :items="data?.audit_log_data" />
    </a-form>
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';

  .setup-drawer-form .ant-form-item {
    margin-bottom: 0px;
  }
</style>
