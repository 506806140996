<script setup lang="tsx">
  import CustomDropdown from '~/components/UI/Forms/CustomDropdown.vue';
  import CustomInput from '~/components/UI/Forms/CustomInput.vue';
  import CustomTextArea from '~/components/UI/Forms/CustomTextArea.vue';
  import { get, post } from '~/services/donationScreen';
  import type { GetResponse, StockItemDrawer } from '~/types';

  const props = defineProps<{
    onClose: () => void;
    identifier: string;
  }>();

  const route = useRoute();

  const action = computed(() => {
    return route.query.action as string;
  });

  const formRef = ref();
  const formState = reactive({
    itemName: '',
    unitOfMeasurement: '',
    itemDescription: ''
  });

  const fetchData = async () => {
    try {
      const stockItemResponse = await get<GetResponse<StockItemDrawer>>(`v1/get_stock_item/${props.identifier}`);
      if (stockItemResponse.data) {
        Object.assign(formState, {
          itemName: stockItemResponse?.data?.data?.selected_stock_item?.name || '',
          unitOfMeasurement: stockItemResponse?.data?.data?.selected_stock_item?.units || '',
          itemDescription: stockItemResponse?.data?.data?.selected_stock_item?.description || ''
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const rules = {
    itemName: [
      {
        required: true,
        message: 'Item Name is required',
        trigger: 'submit'
      }
    ],
    unitOfMeasurement: [
      {
        required: true,
        message: 'Unit of Measurement is required',
        trigger: 'submit'
      }
    ],
    itemDescription: []
  };

  const updateStateField = (value: string, stateKey: keyof typeof formState) => {
    (formState as any)[stateKey] = value;
  };

  const handleCreateStockItem = async () => {
    try {
      await formRef.value.validate();
      const endpoint = action.value === 'edit' ? `v1/stock_item/${props.identifier}` : 'v1/add_new_stock_item';
      await post(endpoint, {
        data: { name: formState.itemName, units: formState.unitOfMeasurement, description: formState.itemDescription }
      });
      openSuccessfulCustomNotification('Stock Item Created Successfully');
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log(error);
      openFailedCustomNotification('Failed to Create Stock Item');
    }
  };

  onMounted(() => {
    if (props.identifier) {
      fetchData();
    }
  });
</script>

<template>
  <a-drawer
    :open="route.query.drawerFrom === 'stockItems'"
    title="Add New Stock Item"
    width="750"
    class="monetary-drawer"
    :body-style="{ padding: '0px' }"
    :header-style="{
      padding: '12px',
      borderBottom: '1px solid #E4E4E7'
    }"
    @close="props.onClose"
  >
    <template #extra>
      <a-button
        v-if="!props.identifier || action"
        class="!px-3 !py-2 w-fit h-fit !rounded"
        type="primary"
        @click="handleCreateStockItem"
        >Create Stock Item</a-button
      >
    </template>
    <a-form
      ref="formRef"
      class="flex flex-col gap-4 p-3 setup-drawer-form"
      :model="formState"
      :rules="rules"
      :colon="false"
      :label-col="{ style: { width: '250px', textAlign: 'left' } }"
      hide-required-mark="true"
    >
      <CustomInput
        label-text="Stock Item Name"
        name="itemName"
        :value="formState.itemName"
        state-key="itemName"
        :is-compulsory="true"
        @update:value="updateStateField"
      />
      <CustomDropdown
        :options="[
          { title: 'Individuals', value: 'Individuals' },
          { title: 'Corporate', value: 'Corporate' },
          { title: 'Foundation', value: 'Foundation' },
          { title: 'Government', value: 'Government' },
          { title: 'Anonymous', value: 'Anonymous' },
          { title: 'NGO', value: 'NGO' }
        ]"
        label-text="Unit of Measurement"
        name="unitOfMeasurement"
        :value="formState.unitOfMeasurement"
        state-key="unitOfMeasurement"
        :rule="rules.unitOfMeasurement"
        :is-compulsory="true"
        @update:value="updateStateField"
      />
      <CustomTextArea
        label="Description"
        name="itemDescription"
        :value="formState.itemDescription"
        state-key="itemDescription"
        @update:value="updateStateField"
      />
    </a-form>
  </a-drawer>
</template>

<style>
  @import '~/assets/css/donationDrawer.css';

  .setup-drawer-form .ant-form-item {
    margin-bottom: 0px;
  }
</style>
